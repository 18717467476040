// @flow

import { Col, Row, Steps } from 'antd';
import {
  CreditCardOutlined,
  FormOutlined,
  HomeOutlined,
  SmileOutlined,
  UploadOutlined
} from '@ant-design/icons';

import React from 'react';

const {Step} = Steps;

const HowItWorksSteps = ({direction}) => {

  return (
    <Steps current={5} labelPlacement={'vertical'} direction={direction}>
      <Step title="Create letter files." description='PDF, JPG/JPEG and PNG files you would like to be posted in a letter via snail mail.' icon={<FormOutlined style={{fontSize: '32px'}} />} />
      <Step title="Upload the files." description='Simply drag and drop your files to PostSeal.' icon={<UploadOutlined style={{fontSize: '32px'}} />} />
      <Step title="Fill in address details." description='Return and destination addresses.' icon={<HomeOutlined style={{fontSize: '32px'}} />} />
      <Step title="Checkout." description='Confirm your purchase.' icon={<CreditCardOutlined style={{fontSize: '32px'}} />} />
      <Step title='Relax.' description='We will print, seal, stamp and send your letter.' icon={<SmileOutlined style={{fontSize: '32px'}} />} />
    </Steps>
  );
};

const HowItWorksView = () => {
  return (
    <>
      <h2 className='ant-typography text-center'>
        Let's simplify sending mail in a few steps
      </h2>
      <p className='text-center'>
        Post a letter from anywhere
      </p>
      <Row className='top-space-2x'>
        <Col xs={0} md={24}>
          <HowItWorksSteps direction='horizontal' />
        </Col>
        <Col xs={24} md={0}>
          <HowItWorksSteps direction='vertical' />
        </Col>
      </Row>

    </>
  );
};

export default HowItWorksView;
